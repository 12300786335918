<template>
  <div class="FootersBottom min_wrapper_1500" v-show="$route.path !== '/login' ">
    <div class="FootersBottom_div">
      <div>
        <div style="display: flex;">
          <div class="fbk">
            <div class="title_b">快速导航</div>
            <ul class="f_list">
              <li><a @click="toBid(41)" style="cursor:pointer">废旧物资</a></li>
              <li><a @click="toBid(44)" style="cursor:pointer">闲置物资</a></li>
              <li><a @click="toBid(47)" style="cursor:pointer">房产</a></li>
              <li><a @click="toNews(2)" style="cursor:pointer">拍卖资讯</a></li>
            </ul>
          </div>
          <div class="fbk">
            <div class="title_b"></div>
            <ul class="f_list">
              <li><a @click="toBid(42)" style="cursor:pointer">二手设备</a></li>
              <li><a @click="toBid(45)" style="cursor:pointer">机动车</a></li>
              <li><a @click="toBid(48)" style="cursor:pointer">土地</a></li>
              <li><a @click="toNews(1)" style="cursor:pointer">信息中心</a></li>
            </ul>
          </div>
          <div class="fbk">
            <div class="title_b"></div>
            <ul class="f_list">
              <li><a @click="toBid(43)" style="cursor:pointer">整厂拆除</a></li>
              <li><a @click="toBid(46)" style="cursor:pointer">股权债券</a></li>
              <li><a @click="toBid(49)" style="cursor:pointer">其他拍卖</a></li>
              <li><a @click="$router.push('/Help')" style="cursor:pointer">帮助中心</a></li>
            </ul>
          </div>
          <div class="fbk">
            <div class="title_b">联系我们</div>
            <div class="f_nr">
              <table cellspacing="0" cellpadding="0">
                <tr valign="bottom">
                  <td height="31" colspan="2">客服热线：13683327721</td>
                </tr>
                <tr valign="bottom">
                  <td height="31" colspan="2">客服QQ：1469183947</td>
                </tr>
                <tr valign="bottom">
                  <td height="31" colspan="2">邮箱：hbzspm@126.com</td>
                </tr>
                <tr valign="bottom">
                  <td height="31" colspan="2">地址：河北省石家庄市裕华区槐安路105号怀特商业广场写字楼B座701室</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="erweima"><img src="../../src/static/image/app_code.png"
                                    class="erweima_1"><span
              class="erweima_2" style="color: rgb(255, 255, 255);">鑫好拍网APP</span></div>
          <div class="erweima"><img src="../../src/static/image/app_code1.png"
                                    class="erweima_1"><span
              class="erweima_2" style="color: rgb(255, 255, 255);">官方微信账号</span></div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="FootersBottom_div_bottom">
        <div class="FootersBottom_div_bottom_left">
          <div class="FootersBottom_div_bottom_left_one"
               style="display: flex; align-items: center; font-size: 12px; margin-top: 10px;margin-left: 13px;"> 鑫好拍网-河北忠尚拍卖有限公司
            &nbsp;&nbsp;|&nbsp;&nbsp;备案号： <a href="http://beian.miit.gov.cn" target="_blank"
                                                style="font-size: 12px; margin-right: 0px;">冀ICP备2020030980号-1</a>
            &nbsp;&nbsp;|&nbsp;&nbsp; <a target="_blank"
                                         href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202003277"
                                         style="font-size: 12px; margin-right: 0px;">
              <div style="display: flex; align-items: center;"><img
                  src="https://www.yunpaiwang.com/img/jigongan.d0289dc0.png"
                  style="display: block; margin-right: 2px;">冀公网备案13010802001327号
              </div>
            </a> &nbsp;&nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {


  },
  methods: {
    toBid(num) {
      this.$router.push('/bid?category_id=' + num);
    },
    toNews(num) {
      this.$router.push('/newsList?tabcur=' + num);
    }
  },
  watch: {}
}
</script>

<style scoped>
.FootersBottom {
  min-width: 1500px;
  background: #484848 url('../../src/static/image/footer.png') left bottom repeat;
  height: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FootersBottom_div {
  width: 1300px;
  height: 260px;
  margin: 0 auto;
  color: #fff;
}

.fbk {
  width: auto;
  margin: 0 0 0 45px;
  padding-right: 45px;
}

.title_b {
  font-size: 15px;
  height: 54px;
  line-height: 54px;
  color: #fff;
  position: relative;
  padding-bottom: 6px;
  padding-left: 10px;
}

.f_list {
  margin-top: 6px;
  font-size: 12px;
}

li, p, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.f_list li {
  line-height: 32px;
  height: 32px;
  padding-left: 10px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.FootersBottom_div_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
}

.FootersBottom_div_bottom_left_one {
  color: #999;
}

.FootersBottom_div_bottom_left_one a {
  margin-right: 10px;
  font-size: 14px;
  color: #999;
}

.FootersBottom a:link, a:active, a:hover, a:visited {
  text-decoration: none;
}

.f_list li a {
  color: #fff;
  font-size: 12px;
}

.erweima_2 {
  float: left;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.erweima {
  height: 130px;
  margin: 20px 20px 50px 20px;
}

.erweima_1 {
  height: 100px;
}

.erweima, .erweima_1 {
  float: left;
  width: 100px;
}
</style>
