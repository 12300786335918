// 引入路由vueRouter
import Vue from 'vue';
import VueRouter from 'vue-router';
// 应用路由VueRouter
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;


VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../view/home/index.vue'),
        meta: {
            title: '首页',
            isLogin: false,
        }
    },
	{
	    path: '*',
	    name: '404',
	    component: () => import('../view/404.vue'),
	    meta: {
	        title: '404',
	        isLogin: false,
	    }
	},
	{
	    path: '/cuowu',
	    name: 'chaoshi',
	    component: () => import('../view/chaoshi.vue'),
	    meta: {
	        title: '错误',
	        isLogin: false,
	    }
	},
	{
	    path: '/aa',
	    name: 'aa',
	    component: () => import('../../src/components/verify.vue'),
	    meta: {
	        title: 'aa',
	        isLogin: false,
	    }
	},
    {
        path: '/index',
        name: 'index',
        redirect: '/bid',
        component: () => import('../view/index/index.vue'),
        children: [
            {
                path: '/bid',//标的
                name: 'bid',
                component: () => import('../view/bid/index.vue'),
                meta: {
                    title: '标的',
                    isLogin: false,
                }
            },
            {
                path: '/auction',//拍卖会
                name: 'auction',
                component: () => import('../view/auction/index.vue'),
                meta: {
                    title: '拍卖会',
                    isLogin: false,
                }
            },
            {
                path: '/price',//钢价行情
                name: 'price',
                component: () => import('../view/quotation/index.vue'),
                meta: {
                    title: '钢价行情',
                    isLogin: false,
                }
            },
            {
                path: '/deal',//成交案例
                name: 'deal',
                component: () => import('../view/deal/index.vue'),
                meta: {
                    title: '成交案例',
                    isLogin: false,
                }
            },
            {
                path: '/qiyesub',//企业入驻 入驻企业注册
                name: 'qiyesub',
                component: () => import('../view/enter/qiyesub.vue'),
                meta: {
                    title: '企业入驻',
                    isLogin: false,
                }
            },

            {
                path: '/broadcast',//企业入驻 入驻企业注册
                name: 'broadcast',
                component: () => import('../view/broadcast/broadcast.vue'),
                meta: {
                    title: '在线直播',
                    isLogin: false,
                }
            },
            {
                path: '/auctionfirm',//拍卖企业
                name: 'auctionfirm',
                component: () => import('../view/auctionfirm/index.vue'),
                meta: {
                    title: '拍卖企业',
                    isLogin: false,
                }
            },
            {
                path: '/login',//登录
                name: 'login',
                component: () => import('../view/login/login.vue'),
                meta: {
                    title: '登录',
                    isLogin: false,
                }
            },
            {
                path: '/register',//注册
                name: 'register',
                component: () => import('../view/login/register.vue'),
                meta: {
                    title: '注册',
                    isLogin: false,
                }
            },
            {
                path: '/forget',//重置密码
                name: 'forget',
                component: () => import('../view/login/forget.vue'),
                meta: {
                    title: '忘记密码',
                    isLogin: false,
                }
            },
            // 以下是我的竞拍路由开始
            {
                path: '/user',//我的云拍
                name: 'user',
                redirect: '/user/account',
                component: () => import('../view/userInfo/index.vue'),
                meta: {
                    title: '我的云拍',
                    isLogin: true,//为true表示页面是否需要登录才能查看
                },
                children: [
                    {
                        path: '/user/account',//账户信息
                        name: 'useraccount',
                        component: () => import('../view/userInfo/common/account.vue'),
                        meta: {
                            title: '账户信息',
                            isLogin: true,//为true表示页面是否需要登录才能查看
                        },
                    },
                    {
                        path: '/user/name',//实名认证
                        name: 'username',
                        component: () => import('../view/userInfo/common/name.vue'),
                        meta: {
                            title: '实名认证',
                            isLogin: true,//为true表示页面是否需要登录才能查看
                        },
                    },
                    {
                        path: '/user/person',//个人信息
                        name: 'userperson',
                        component: () => import('../view/userInfo/common/person.vue'),
                        meta: {
                            title: '个人信息',
                            isLogin: true,//为true表示页面是否需要登录才能查看
                        },
                    },
                    {
                        path: '/user/person_info',//个人信息的修改信息
                        name: 'userperson_info',
                        component: () => import('../view/userInfo/common/person_info.vue'),
                        meta: {
                            title: '修改个人信息',
                            isLogin: true,//为true表示页面是否需要登录才能查看
                        },
                    },
                    {
                        path: '/user/unsubscribe',//注销账户
                        name: 'userunsubscribe',
                        component: () => import('../view/userInfo/common/unsubscribe.vue'),
                        meta: {
                            title: '注销账户',
                            isLogin: true,//为true表示页面是否需要登录才能查看
                        },
                    },
					{
					    path: '/user/MyAuction',//我的竞拍
					    name: 'userMyAuction',
					    component: () => import('../view/userInfo/common/MyAuction.vue'),
					    meta: {
					        title: '我的竞拍',
					        isLogin: true,//为true表示页面是否需要登录才能查看
					    },
					},
					{
					    path: '/user/MyAttention',//我的竞拍
					    name: 'userMyAttention',
					    component: () => import('../view/userInfo/common/MyAttention.vue'),
					    meta: {
					        title: '我的关注',
					        isLogin: true,//为true表示页面是否需要登录才能查看
					    },
					}
                ]
            },
            // 以上是我的竞拍路由结束
            {
                path: '/targetmulu',//拍卖会详情
                name: 'targetmulu',
                component: () => import('../view/targetmulu/index.vue'),
                meta: {
                    title: '拍卖会详情',
                    isLogin: false,
                }
            },
            {
                path: '/professionDetails',//标的详情
                name: 'professionDetails',
                component: () => import('../view/professionDetails1/index.vue'),
                meta: {
                    title: '标的详情2',
                    isLogin: false,
                }
            },
            {
                path: '/notice',//拍卖公告
                name: 'notice',
                component: () => import('../view/notice/index.vue'),
                meta: {
                    title: '拍卖公告',
                    isLogin: false,
                }
            },
            {
                path: '/noticeDetail',//公告详情
                name: 'noticeDetail',
                component: () => import('../view/notice/noticeDetail.vue'),
                meta: {
                    title: '公告详情',
                    isLogin: false,
                }
            },
            {
                path: '/news',//行业资讯
                name: 'news',
                component: () => import('../view/news/index.vue'),
                meta: {
                    title: '行业资讯',
                    isLogin: false,
                }
            },
            {
                path: '/about',//企业介绍
                name: 'about',
                component: () => import('../view/about/index.vue'),
                meta: {
                    title: '企业介绍',
                    isLogin: false,
                }
            },
            {
                path: '/broadcastDetail',//直播详情
                name: 'broadcastDetail',
                component: () => import('../view/broadcast/broadcastDetail.vue'),
                meta: {
                    title: '直播详情',
                    isLogin: false,
                }
            },
            {
                path: '/formBid',//报名提交
                name: 'formBid',
                component: () => import('../view/formBid/formBid.vue'),
                meta: {
                    title: '报名提交',
                    isLogin: true,//为true表示页面是否需要登录才能查看
                }
            },
            {
                path: '/investment',//循环物资招商
                name: 'investment',
                component: () => import('../view/investment/index.vue'),
                meta: {
                    title: '循环物资招商',
                    isLogin: false,
                }
            },
            {
                path: '/investmentDetal',//循环物资招商
                name: 'investmentDetal',
                component: () => import('../view/investment/detal.vue'),
                meta: {
                    title: '循环物资招商详情',
                    isLogin: false,
                }
            },
            {
                path: '/Help',//帮助中心
                name: 'Help',
                component: () => import('../view/Help/index.vue'),
                meta: {
                    title: '帮助中心',
                    isLogin: false,
                }
            },
            {
                path: '/newsList',//资讯信息
                name: 'newsList',
                component: () => import('../view/newsList/index.vue'),
                meta: {
                    title: '资讯信息',
                    isLogin: false,
                }
            },
            {
                path: '/newsDetails',//资讯信息详情
                name: 'newsDetails',
                component: () => import('../view/newsList/newsDetails.vue'),
                meta: {
                    title: '资讯信息详情',
                    isLogin: false,
                }
            },
        ]
    },
]

const router = new VueRouter({
    linkActiveClass: 'selected',
    routes,
    mode: 'history'
})
export default router
