import { getToken, setToken, removeToken } from '@/utils/auth'
const user = {
	namespaced:true,
	state:{
		token:'',
	},
	mutations:{
		// 登录设置信息
		SETUSER(state,token){
			state.token = token
		},
		REMOVEUSER(state,token){
			state.token = ''
		}
	},
	actions:{
		
	}
}



export default user