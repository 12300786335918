// 路由权限
import Vue from 'vue';
import router from './index';
import stort from '../store/index.js';

import ProgressBar from '@/view/ProgressBar.vue';//进度条效果
// 创建进度条实例
const progressBar = new Vue(ProgressBar).$mount()
document.body.appendChild(progressBar.$el)


router.beforeEach((to,from,next) => {
	progressBar.start() // 显示进度条
	
	
	if (!stort.state.user.token) {
		
		// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
		if (to.meta.isLogin === true) {
			
			next('/login')
		}else{
			window.scrollTo(0, 0);//跳转页面后直接到顶部
			next()
		}
	}else{
		// 用户已经登录  放行
		if (to.path === '/login') {
			if (stort.state.user.token === '') {
				next('/login');
			}else{
				next('/user/account');
			}
			 
		}else{
			next()
		}
		
	}
})
router.afterEach((to, from) => {
 window.scrollTo(0, 0);
 
 
 progressBar.finish() // 隐藏进度条
})

